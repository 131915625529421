<template>
    <base-section id="features" class="secondary" space="0">
        <v-responsive class="mx-auto" max-width="1350">
            <v-container fluid>
                <v-row>
                    <v-col v-for="card in cards" :key="card.title" cols="12">
                        <base-info-card
                            align="center"
                            dark
                            v-bind="card"
                            color="secondary lighten-3"
                            text-class="white--text"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-responsive>
    </base-section>
</template>

<script>
export default {
    name: "SectionFeatures",

    data: () => ({
        cards: [
            {
                icon: "mdi-rocket",
                title: "The Power of the Platform",
                text: "Apply Oregon is a cutting-edge platform that enables property managers to advertise their listings effortlessly and efficiently for free to a new class of tenants, using one-of-a-kind Smart Sync technology. With Apply Oregon quickly create new easy-to-read listings for entire properties or current units.",
            },
        ],
    }),
};
</script>
